<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-7 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('shipping_address') }}
				</div>
			</div>
			<div class="col-xs-12 col-lg-5 d-flex">
				<div
					class="h4"
					:class="['xs','sm', 'md'].includes(windowWidth) ? 'mb-2 text-left' : 'my-auto ml-auto text-right'">
					<div class="text-primary">
						<span class="font-weight-bold">{{ translate('sponsor') }}: </span>
						<span>{{ sponsor.name }} </span>
						<span>({{ translate('id_word') }}: {{ sponsor.id }})</span>
						<p
							v-if="positionData !== ''">
							<span class="font-weight-bold">{{ translate('parent') }}: </span>
							<span>{{ positionData.parent }} </span>
							<span>({{ translate('leg') }}: {{ translate(positionData.leg) }})</span>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<form
						@submit.prevent="prepareForStorage()"
						@keydown="clearValidationError($event.target.name);"
						@change="handleAddressFormChange($event.target.name);">
						<li class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('contact_information') }}
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12 col-md-6">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.shipping_address.first_name`"
											:label="translate('first_name')"
											:setter-value="form.first_name"
											:errors="validationErrors[`${steps[$route.name].validation}.shipping_address.first_name`]"
											:required="true"
											type="text"
											@dataChanged="form.first_name = $event" />
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.shipping_address.last_name`"
											:label="translate('last_name')"
											:setter-value="form.last_name"
											:errors="validationErrors[`${steps[$route.name].validation}.shipping_address.last_name`]"
											:required="true"
											type="text"
											@dataChanged="form.last_name = $event" />
									</div>
								</div>
							</div>
						</li>
						<li class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('address_information') }}
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.shipping_address.address`"
											:label="translate('address')"
											:setter-value="form.address"
											:errors="validationErrors[`${steps[$route.name].validation}.shipping_address.address`]"
											:required="true"
											type="text"
											@dataChanged="form.address = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.shipping_address.address2`"
											:label="translate('address2')"
											:setter-value="form.address2"
											:errors="validationErrors[`${steps[$route.name].validation}.shipping_address.address2`]"
											type="text"
											@dataChanged="form.address2 = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.shipping_address.postcode`"
											:label="translate('postcode')"
											:setter-value="form.postcode"
											:errors="validationErrors[`${steps[$route.name].validation}.shipping_address.postcode`]"
											:required="true"
											type="text"
											@dataChanged="form.postcode = $event" />
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.shipping_address.city`"
											:label="translate('city')"
											:setter-value="form.city"
											:errors="validationErrors[`${steps[$route.name].validation}.shipping_address.city`]"
											:required="true"
											type="text"
											@dataChanged="form.city = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<label :for="`${steps[$route.name].validation}.shipping_address.country_code`">{{ translate('country') }} <span class="text-danger">*</span></label>
										<select
											:id="`${steps[$route.name].validation}.shipping_address.country_code`"
											v-model="form.country_code"
											:name="`${steps[$route.name].validation}.shipping_address.country_code`"
											:disabled="!allowAnotherCountry"
											class="form-control">
											<option value="">
												{{ translate('select_country') }}
											</option>
											<template v-if="hasCountries">
												<option
													v-for="item in countries"
													:key="item.id"
													:value="item.attributes.code">
													{{ translate(item.attributes.code.toLowerCase()) }}
												</option>
											</template>
										</select>
										<template v-if="(typeof validationErrors[`${steps[$route.name].validation}.shipping_address.country_code`] !== 'undefined')">
											<span
												v-for="error in validationErrors[`${steps[$route.name].validation}.shipping_address.country_code`]"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger"
												v-text="error" />
										</template>
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<label for="countries">{{ translate('state') }} <span class="text-danger">*</span></label>
										<select
											id="state"
											v-model="form.region_id"
											:name="`${steps[$route.name].validation}.shipping_address.region_id`"
											:class="(typeof validationErrors[`${steps[$route.name].validation}.shipping_address.region_id`] !== 'undefined' ? 'is-invalid' : '')"
											class="form-control">
											<option value="">
												{{ translate('select_state') }}
											</option>
											<template v-if="hasState">
												<option
													v-for="item in states"
													:key="item.id"
													:value="item.id">
													{{ item.attributes.name }}
												</option>
											</template>
										</select>
										<template v-if="(typeof validationErrors[`${steps[$route.name].validation}.shipping_address.region_id`] !== 'undefined')">
											<span
												v-for="error in validationErrors[`${steps[$route.name].validation}.shipping_address.region_id`]"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger"
												v-text="error" />
										</template>
									</div>
								</div>
							</div>
							<!-- <div
								v-if="countriesListForSignature.includes(country)"
								class="row m-3">
								<div class="col-12">
									<switch-toggle
										id="signature_required"
										v-model="form.signature_required"
										name="signature_required"
										variant="success"
										class="mr-2"
										pill />
									<label for="signature_required">{{ translate('signature_label') }} <span class="text-danger">*</span></label>
								</div>
								<div class="col-12">
									<small v-html="translate('signature_disclaimer')" />
								</div>
							</div> -->
							<div
								v-if="showOverrideValidation"
								class="row m-3">
								<div class="col-12">
									<switch-toggle
										id="override_validation"
										v-model="form.override_validation"
										name="override_validation"
										variant="success"
										class="mr-2"
										pill />
									<label for="override_validation">
										{{ translate('override_validation') }}
										<span
											v-b-tooltip.hover
											:title="translate('override_validation_info')"
											class="text-muted">
											<i class="fas fa-lg fa-info-circle" />
										</span>
									</label>
								</div>
							</div>
						</li>
					</form>
					<li class="list-group-item py-0 px-3 px-md-4">
						<div class="row">
							<div class="col-12 py-3">
								<div
									v-if="!loadingCartInfo"
									class="row">
									<div class="col-12">
										<div class="font-weight-bold">
											<span class="h4 align-top">{{ translate('select_shipping_method') }}</span>
										</div>
									</div>
									<template v-if="!loadingShippingMethods || cartInfo.is_virtual">
										<div class="col-12 mt-3">
											<b-alert
												v-if="!fullAddressInForm || cartInfo.is_virtual"
												:variant="cartInfo.is_virtual ? 'info' : 'warning'"
												show>
												{{ translate(cartInfo.is_virtual ? 'shipping_unnecessary' : 'incomplete_address_information') }}
											</b-alert>
											<b-alert
												v-else-if="!hasShippingMethods"
												variant="warning"
												show>
												{{ translate('no_address_found') }}
											</b-alert>
										</div>
										<div
											v-if="hasShippingMethods && !loadingShippingMethods && fullAddressInForm"
											:class="windowWidth === 'xs' ? '': (windowWidth === 'md' ? 'px-4' : 'px-5' )"
											class="col-12 col-md-6 mt-3">
											<div
												v-for="({ attributes: item}, index) in shippingMethods"
												:key="`${item.carrier_code_name}_${item.method_code_name}`"
												class="form-check my-2">
												<input
													:id="`${item.carrier_code_name}_${item.method_code_name}_${index}`"
													v-model="shippingMethod"
													:value="{carrier_code_name: item.carrier_code_name, method_code_name: item.method_code_name}"
													:checked="index === 0"
													class="form-check-input"
													type="radio"
													name="shipping_method">
												<label
													:for="`${item.carrier_code_name}_${item.method_code_name}_${index}`"
													class="form-check-label">
													{{ translate(`${ item.carrier_code_name }_${ item.method_code_name }`) }}
												</label>
												<div class="float-right">
													{{ item.amount }}
												</div>
											</div>
										</div>
									</template>
									<is-loading
										:loading-label="translate('loading')"
										:no-data-label="translate('data_not_found')"
										:loading="loadingShippingMethods"
										:has-data="true"
										class="mx-auto mt-4" />
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item py-0 px-3 px-md-4">
						<div class="row">
							<div class="col-12 py-3 autoship-shipping">
								<div class="row">
									<div class="col-12">
										<div class="font-weight-bold">
											<span class="h4 align-top">{{ translate('select_monthly_autoship_product') }}</span>
										</div>
										<p class="small mb-1 mt-2">
											{{ translate('autoship_description') }}
										</p>
									</div>
									<template v-if="!loadingProducts">
										<div
											v-if="hasProducts && productsReadyAutoship"
											:class="windowWidth === 'xs' ? '' : 'px-3'"
											class="col-12 mt-3">
											<div class="row">
												<div
													v-for="item in products"
													:key="item.id"
													class="col-12 col-md-6 col-lg-4">
													<div
														v-if="item.attributes.available_for_autoship"
														class="row mb-2">
														<div class="col col-sm col-lg-5 d-flex">
															<div class="custom-control custom-checkbox my-auto">
																<input
																	:id="item.attributes.sku"
																	v-model="autoshipProducts[item.attributes.sku].required"
																	name="autoshipProducts[]"
																	type="checkbox"
																	class="custom-control-input">
																<label
																	:for="item.attributes.sku"
																	class="custom-control-label">{{ translate(item.attributes.code_name) }}</label>
															</div>
														</div>
														<div class="col align-self-center">
															<spinner
																v-model="autoshipProducts[item.attributes.sku].value"
																:min="0"
																:max="9999"
																:value="autoshipProducts[item.attributes.sku].value"
																:button-class="'vnis__button bg-primary-alt'"
																:input-class="'vnis__input vnis-input-custom-width'"
																:integer-only="true"
																:mouse-down-speed="500"
																role="button"
																class="vnis-smaller"
																style="min-width:106px;max-width:120px" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</template>
									<is-loading
										:loading-label="translate('loading')"
										:no-data-label="translate('data_not_found')"
										:loading="loadingProducts"
										:has-data="true"
										class="mx-auto mt-4" />
								</div>
								<template v-if="(typeof validationErrors[`${steps[$route.name].validation}.autoship.products`] !== 'undefined')">
									<b-alert
										class="mt-3"
										variant="warning"
										show>
										<span
											v-for="error in validationErrors[`${steps[$route.name].validation}.autoship.products`]"
											:key="error">
											<i class="fa fa-exclamation-circle" />
											{{ error }} <br>
										</span>
									</b-alert>
								</template>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 ml-auto my-4 mb-4 text-right">
				<div class="row">
					<div class="col-12 mb-4 text-left">
						<div class="form-check">
							<input
								id="autoshipAgreement"
								v-model="autoshipAgreement"
								name="autoshipAgreement"
								class="form-check-input"
								type="checkbox">
							<label
								class="form-check-label"
								for="autoshipAgreement"
								v-html="translate('autoship_agreement')" />
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 ml-auto">
						<div
							class="btn-group w-100"
							role="group">
							<b-button
								:disabled="!!preparing"
								variant="light"
								size="lg"
								@click="nextStep(true)">
								{{ translate('previous') }}
							</b-button>
							<b-button
								:disabled="validating || !!preparing || loadingProducts || loadingShippingMethods || !autoshipAgreement"
								variant="primary"
								size="lg"
								@click="prepareForStorage()">
								<i
									v-if="validating"
									class="fas fa-spinner fa-spin mr-2" />
								{{ translate('continue') }}
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ALLOW_CHANGE_COUNTRY_TO_USER as allowAnotherCountry } from '@/settings/AddressBook';
import { UNPROCESSABLE } from '@/settings/Errors';
import { SORTED_PRODUCT_SKUS as sortList } from '@/settings/Products';
import { AUTOSHIP_MIN_QTY } from '@/settings/Wizard';
import {
	SHOW_SIGNATURES_BY_COUNTRY as countriesListForSignature,
	ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries,
	SHIPPING_UPDATABLE_FIELDS as shippingUpdatableFields,
} from '@/settings/Shipping';
import { TIMEZONE } from '@/settings/General';
import AddressBook from '@/util/AddressBook';
import WindowSizes from '@/mixins/WindowSizes';
import switchToggle from '@/components/Switch';
import InputText from '@/components/InputText';
import isLoading from '@/components/Loading';
import Cart from '@/util/Cart';
import Country from '@/util/Country';
import Products from '@/util/Products';
import CommonMix from './mixins/Common';
import PackMix from './mixins/Package';

const { CancelToken } = require('axios');

export default {
	name: 'RegisterOldShipping',
	components: {
		switchToggle,
		InputText,
		isLoading,
	},
	mixins: [CommonMix, PackMix, WindowSizes],
	data() {
		return {
			countryData: new Country(),
			stateData: new Country(),
			prodData: new Products(),
			shippingData: new Cart(),
			cartData: new Cart(),
			shippingMethod: {},
			timing: null,
			sponsor: {
				id: '',
				name: '',
			},
			identityId: null,
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				signature_required: false,
				override_validation: false,
			},
			autoshipAgreement: false,
			allowAnotherCountry,
			alert: new this.$Alert(),
			getSuggestion: new AddressBook(),
			countriesListForSignature,
			WindowSizes,
			timezone: TIMEZONE,
			productsReadyAutoship: false,
			autoshipProducts: {},
			showOverrideValidation: false,
			addressVerificationCountries,
			autoshipMinQty: AUTOSHIP_MIN_QTY,
			cancel: () => null,
			shippingUpdatableFields,
		};
	},
	computed: {
		products() {
			try {
				const productsData = this.prodData.data.response.data.data;
				productsData.sort((a, b) => {
					const aIndex = sortList.indexOf(a.attributes.sku);
					const bIndex = sortList.indexOf(b.attributes.sku);

					if (aIndex === -1) return 1;
					if (bIndex === -1) return -1;

					return aIndex - bIndex;
				});
				return productsData;
			} catch (error) {
				return [];
			}
		},
		loadingProducts() {
			try {
				return this.prodData.data.loading;
			} catch (error) {
				return true;
			}
		},
		hasProducts() {
			return !!this.products.length;
		},
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				if (allowAnotherCountry === false) {
					return response.filter((item) => item.attributes.code === this.country);
				}
				return response;
			} catch (error) {
				return [];
			}
		},
		hasCountries() {
			return !!this.countries.length;
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasState() {
			return !!this.states.length;
		},
		shippingStep() {
			return Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
		},
		savedShipping() {
			const step = this.shippingStep;
			const { shipping } = { ...this.getStepInformation(step) };
			return shipping;
		},
		shippingMethods() {
			try {
				return this.shippingData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loadingShippingMethods() {
			try {
				return this.shippingData.data.loading;
			} catch (error) {
				return false;
			}
		},
		hasShippingMethods() {
			return !!this.shippingMethods.length;
		},
		fullAddressInForm() {
			const {
				address, country_code: country, postcode, region_id: state, city,
			} = this.form;
			if (address.trim().length && country.trim().length && postcode.trim().length && state > 0 && city.trim().length) {
				return true;
			}
			return false;
		},
		cartInfo() {
			try {
				return this.cartData.data.response.data.response;
			} catch (error) {
				return [];
			}
		},
		loadingCartInfo() {
			try {
				return this.cartData.data.loading;
			} catch (error) {
				return false;
			}
		},
	},
	watch: {
		autoshipProducts(value) {
			this.createWatchesForProducts(value);
		},
	},
	created() {
		this.countryData.getCountries();
		this.getGeneralInformation();
		this.initializeAutoship();
		this.getStoredInformation();
		this.form.country_code = this.country;
		this.stateData.getStates(this.form.country_code);
	},
	methods: {
		initializeAutoship() {
			this.prodData.getProducts(this.getStoredCountry(), undefined, { isRegister: true }).then(() => {
				// if (typeof this.savedShipping.autoship !== 'undefined' && typeof this.savedShipping.autoship.products === 'object') {
				// 	this.autoshipEnabled = !!Object.keys(this.savedShipping.autoship.products).length;
				// }

				if (typeof this.savedShipping === 'undefined' || typeof this.savedShipping.autoship === 'undefined' || Object.keys(this.savedShipping.autoship.products).length === 0) {
					let remainingQty = this.autoshipMinQty[this.country];
					for (let index = 0; index < this.products.length; index += 1) {
						const currentProductSKU = this.products[index].attributes.sku;
						const currentProduct = this.autoshipProducts[currentProductSKU];

						if (remainingQty > 0) {
							this.$set(this.autoshipProducts, currentProductSKU, {
								value: currentProduct ? currentProduct.value += 1 : 1,
								required: true,
							});
							remainingQty -= 1;
							const nextIndex = index + 1;
							// When we increment the last product qty and there's remainingQty left,
							if (nextIndex >= this.products.length && remainingQty > 0) {
								// we 'reset' our accumulator, so we can go back to the 1st product
								index = -1;
							}
						} else {
							this.$set(this.autoshipProducts, currentProductSKU, {
								value: currentProduct ? currentProduct.value += 0 : 0,
								required: currentProduct ? currentProduct.required : false,
							});
						}
					}
				} else {
					this.products.forEach((item) => {
						if (typeof this.savedShipping.autoship.products[item.attributes.sku] !== 'undefined') {
							this.$set(this.autoshipProducts, item.attributes.sku, { value: this.savedShipping.autoship.products[item.attributes.sku], required: true });
						} else {
							this.$set(this.autoshipProducts, item.attributes.sku, { value: 0, required: false });
						}
					});
				}
			});
			return null;
		},
		getGeneralInformation() {
			const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'personal_information');
			const previous = this.getStepInformation(step);
			const {
				sponsor, personal_information: personalInformation, identity_id: identityId,
			} = previous;

			this.cartData.getCartInfo(this.shoppingCartId);
			if (typeof sponsor === 'undefined' || typeof personalInformation === 'undefined') {
				this.nextStep(true);
				return null;
			}
			this.identityId = identityId;
			this.getSponsorInfo(sponsor).then((response) => {
				Object.keys(this.sponsor).forEach((value) => {
					this.sponsor[value] = response[value];
				});
			});
			if (typeof personalInformation.first_name === 'string') {
				this.form.first_name = personalInformation.first_name;
			}
			if (typeof personalInformation.last_name === 'string') {
				this.form.last_name = personalInformation.last_name;
			}
			return null;
		},
		getStoredInformation() {
			try {
				const { shipping_address: data } = this.savedShipping;
				this.shippingMethod = this.savedShipping.shipping_method;
				const { country_code: countryCode } = data;
				Object.keys(this.form).forEach((key) => {
					if (typeof data[key] !== 'undefined') {
						this.form[key] = data[key];
						if (key === 'override_validation') {
							this.showOverrideValidation = true;
						}
					}
				});
				if (countryCode !== this.country) {
					this.form.region_id = '';
				}
			} catch (error) {
				//
			}
			if (this.fullAddressInForm) {
				this.getShippingMethods();
			}
			return null;
		},
		prepareForStorage() {
			this.preparing = true;
			const shipping = {
				shipping_address: { ...this.form },
				shipping_method: { carrier_code_name: null, method_code_name: null },
			};
			let autoship = {};
			if (Object.keys(this.autoshipProducts).length > 0) {
				Object.keys(this.autoshipProducts).forEach((item) => {
					const product = {};
					if (this.autoshipProducts[item].required === true) {
						product[item] = this.autoshipProducts[item].value;
						autoship = { ...autoship, ...product };
					}
				});
			}
			if (Object.keys(autoship).length > 0) {
				shipping.autoship = { products: { ...autoship } };
			}
			if (typeof this.shippingMethod !== 'undefined') {
				if (Object.keys(this.shippingMethod).length) {
					shipping.shipping_method = { ...this.shippingMethod };
				}
			}
			let payload = {
				step: this.steps[this.$route.name].validation, sponsor: this.sponsor.id, shipping, identity_id: this.identityId,
			};
			if (!this.showOverrideValidation) {
				payload.shipping.shipping_address.override_validation = undefined;
			}
			this.validateStep(payload).then((response) => {
				payload = { ...payload, shipping_data: { ...response.response } };
				this.saveStep(payload);
			}).catch((errors) => {
				if (UNPROCESSABLE.includes(errors.status)) {
					Object.keys(errors.errors).forEach((errorKey) => {
						if (errorKey === 'shipping.shipping_address.suggestion') {
							this.getSuggestion.getSuggestion(this.form).then((suggestionResponse) => {
								let address = '';
								Object.keys(suggestionResponse.response.address).forEach((item, index) => {
									if (suggestionResponse.response.address[item] !== '' && item !== 'region_id') {
										address += `${suggestionResponse.response.address[item]}`;
										if (index < (Object.keys(suggestionResponse.response.address).length) - 2) {
											address += ', ';
										}
									}
								});
								const trans = {
									title: this.translate('invalid_address'),
									text: this.translate('suggestion_address', { address }),
								};
								const options = {
									config: {
										icon: 'warning',
										showCancelButton: true,
										confirmButtonText: this.translate('accept_sugestion'),
										cancelButtonText: this.translate('manual_change'),
										reverseButtons: true,
										allowOutsideClick: false,
										allowEscapeKey: false,
									},
								};
								this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
									Object.keys(suggestionResponse.response.address).forEach((suggestionItem) => {
										Object.keys(this.form).forEach((formItem) => {
											if (formItem === suggestionItem) {
												this.form[formItem] = suggestionResponse.response.address[suggestionItem];
											}
										});
									});
									this.getShippingMethods();
								}).catch(() => {});
							});
						} else if (errorKey.includes('shipping.shipping_address.address') && !!this.form.address.trim().length && this.addressVerificationCountries.includes(this.form.country_code)) {
							this.showOverrideValidation = true;
						}
					});
				}
				this.preparing = false;
			});
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'shipping.shipping_address.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
			const targetValue = target.split('.').pop();
			if (this.shippingUpdatableFields.includes(targetValue)) this.getShippingMethods();
		},
		createWatchesForProducts(value) {
			Object.keys(value).forEach((item) => {
				this.$watch(() => this.autoshipProducts[item].required, (newVal) => {
					this.clearValidationError(`${this.steps[this.$route.name].validation}.autoship.products`);
					if (newVal) {
						if (this.autoshipProducts[item].value === 0) {
							this.autoshipProducts[item].value = 1;
						}
					} else {
						this.autoshipProducts[item].value = 0;
					}
				});
				this.$watch(() => this.autoshipProducts[item].value, (newVal) => {
					this.clearValidationError(`${this.steps[this.$route.name].validation}.autoship.products`);
					if (newVal > 0) {
						this.autoshipProducts[item].required = true;
					} else {
						this.autoshipProducts[item].required = false;
					}
				});
			});
			this.productsReadyAutoship = true;
		},
		getShippingMethods() {
			this.clearValidationError('shipping.shipping_method.carrier_code_name');
			if (!this.fullAddressInForm || this.cartInfo.is_virtual) {
				return null;
			}
			if (this.timing) {
				clearTimeout(this.timing);
			}
			if (this.cancel) this.cancel();
			this.timing = setTimeout(() => {
				const payload = {
					first_name: this.form.first_name,
					last_name: this.form.last_name,
					address: this.form.address,
					address2: this.form.address2,
					postcode: this.form.postcode,
					city: this.form.city,
					country_code: this.form.country_code,
					region_id: this.form.region_id,
				};
				this.shippingData.getShippingMethod(this.shoppingCartId, payload, {
					cancelToken: new CancelToken((c) => { this.cancel = c; }),
				}).then((response) => {
					if (response.length === 0) {
						return null;
					}

					this.shippingMethod = !Object.keys(this.shippingMethod).length ? { carrier_code_name: response[0].attributes.carrier_code_name, method_code_name: response[0].attributes.method_code_name } : this.shippingMethod;
					return null;
				}).catch(() => {}).finally(() => {
					this.cancel = null;
				});
			}, 500);
			return null;
		},
	},
};
</script>
